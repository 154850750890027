<template>
  <section v-if="myLesson" v-loading="loading">
    <my-lesson-form
      :lesson="myLesson"
      :isLastLessonInModule="isLastLessonInModule"
      :isFirstLessonInCourse="isFirstLessonInCourse"
      :isLastLessonInCourse="isLastLessonInCourse"
      @onNextClick="handleNextLesson"
      @nextLecture="nextLecture"
      @prevLecture="prevLecture"
      :courseCompleted="courseCompleted"
    ></my-lesson-form>
  </section>
</template>

<script>
import MyLessonForm from '../components/MyLessonForm';
import { getMyLesson, postMyLesson } from '../api';

export default {
  props: {
    lessonId: {
      type: Number,
      required: true
    },
    // Module related
    isLastLessonInModule: {
      type: Boolean,
      required: false
    },
    // Course related
    isFirstLessonInCourse: {
      type: Boolean,
      required: false
    },
    isLastLessonInCourse: {
      type: Boolean,
      required: false
    },
    courseCompleted: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      myLesson: null,
      loading: false
    };
  },

  components: {
    MyLessonForm
  },

  watch: {
    lessonId: function(newVal, oldVal) {
      this.getLesson();
    }
  },

  methods: {
    async getLesson() {
      const id = this.$props.lessonId;
      this.loading = true;
      try {
        const lesson = await getMyLesson(id);
        this.myLesson = lesson;
        this.loading = false;
      } catch (error) {
        this.$message({
          message: `${this.$t('errors.general')}`,
          type: 'error'
        });
        this.loading = false;
      }
    },

    async handleNextLesson(lesson) {
      this.loading = true;
      try {
        const result = await postMyLesson(lesson);
        this.completedLesson(result);
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$tc('errors.general'), type: 'error' });
        this.loading = false;
      }
    },
    completedLesson(userLesson) {
      this.$emit('completedLesson', userLesson);
    },
    nextLecture() {
      this.$emit('nextLecture');
    },
    prevLecture() {
      this.$emit('prevLecture');
    }
  },

  created() {
    this.getLesson();
  }
};
</script>

<style></style>
