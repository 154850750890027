<template>
  <section class="image-container">
    <div
      v-for="image in images"
      :key="image.id"
      :class="`${image.orientation}`"
    >
      <div class="cursor-pointer">
        <el-image
          @click="selectImage(image)"
          class="mx-1"
          :src="`${imageRootPath}/${image.fileName}`"
          :fit="'contain'"
          :preview-src-list="srcList.map(src => `${imageRootPath.replaceAll('/images', '')}${src}`)"
        >
        </el-image>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true
    }
  },

  data: function() {
    return {
      srcList: [],      
      imageRootPath: process.env.VUE_APP_AZURE_BLOB_URL
    };
  },

  watch: {
    images: {
      handler: function() {
        let list = [];
        this.images.forEach(image => {
          list.push('/images/' + image.fileName);
        });
        this.srcList = list;
      }
    }
  },

  methods: {
    selectImage(clickedImage) {
      let list = [];
      let imagesAfterSelectedImage = [];
      let afterSelectedImage = false;
      this.images.forEach(image => {
        if (image !== clickedImage && !afterSelectedImage) {
          list.push('/images/' + image.fileName);
        } else {
          afterSelectedImage = true;
        }

        if (image !== clickedImage && afterSelectedImage) {
          imagesAfterSelectedImage.push(image);
        }
      });

      // Push the images that are after the selected image now
      imagesAfterSelectedImage.reverse().forEach(image => {
        if (image !== clickedImage && afterSelectedImage) {
          list.unshift('/images/' + image.fileName);
        }
      });

      // Lastly add the selected image first in list
      list.unshift('/images/' + clickedImage.fileName);
      this.srcList = list;
    }
  }
};
</script>

<style>
.center {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
}
.image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.el-image-viewer__mask,
.el-image-viewer__img {
  cursor: default;
}
.el-image__inner:hover,
.el-image__preview:hover {
  opacity: 0.8;
  cursor: pointer;
}
.el-image__inner {
  max-height: 315px;
  width: auto;
}
</style>
