<template>
  <div v-loading="loading">
    <div class="bg-white rounded overflow-hidden" v-if="myCourse">
      <my-course-form v-if="myCourse" :course="myCourse"></my-course-form>
      <div class="course-nav pt-4 md:pt-6">
        <el-select
          v-model="selectedLesson"
          @change="onLessonChange"
          placeholder="Select"
          class="lesson_selector"
        >
          <el-option-group
            v-for="mod in myCourse.courseModules"
            :key="`mod_${mod.id}`"
            :label="getTranslation(mod.name)"
          >
            <el-option
              v-for="les in mod.lessons"
              :key="`les_${les.id}`"
              :label="getTranslation(les.name)"
              :value="les.id"
              :disabled="les.disabled"
              class="lesson_selector_option"
            ></el-option>
          </el-option-group>
        </el-select>
      </div>
      <el-divider></el-divider>
      <my-lesson
        :lessonId="selectedLesson"
        :isLastLessonInModule="isLastLessonInModule"
        :isFirstLessonInCourse="isFirstLessonInCourse"
        :isLastLessonInCourse="isLastLessonInCourse"
        @completedLesson="onCompletedLesson"
        @nextLecture="navNext"
        @prevLecture="navBack"
        :courseCompleted="myCourse.completed ? true : false"
      ></my-lesson>
    </div>
  </div>
</template>

<script>
import MyCourseForm from '../components/MyCourseForm';
import MyLesson from './MyLesson';
import {getMyCourseById, getMyCourses} from '../api';

export default {
  data() {
    return {
      myCourse: null,
      selectedLesson: null,
      isLastLessonInModule: false,
      loading: false,
      flatLessons: []
    };
  },

  components: {
    MyCourseForm,
    MyLesson
  },

  methods: {
    async getMyCourse(mcid) {
      this.loading = true;
      try {
        const course = await getMyCourseById(mcid);
        this.manipulateCourseSelection(course);
        this.myCourse = course;
        this.selectedLesson = this.getFirstUncompletedOrFirst(course);
        this.checkIfLastLessonInModule(this.selectedLesson, course);
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    manipulateCourseSelection(course) {
      const completed = course.userLessons;
      const flat = [];
      let foundNext = false;
      course.courseModules.forEach(mod => {
        mod.lessons.forEach(les => {
          flat.push(les);
          const res = completed.find(c => c.lessonId === les.id);
          if (res) {
            les.diabled = false;
          } else {
            if (foundNext) {
              les.disabled = true;
            } else {
              les.disabled = false;
              foundNext = true;
            }
          }
        });
      });
      if (flat) this.flatLessons = flat;
    },

    getFirstUncompletedOrFirst(course) {
      let firstUncompleted = undefined;
      course.courseModules.forEach(mod => {
        if (!firstUncompleted) {
          firstUncompleted = mod.lessons.find(l => {
            return !course.userLessons.some(ul => ul.lessonId === l.id);
          });
        }
      });
      if (!firstUncompleted) {
        firstUncompleted = course.courseModules[0].lessons[0];
      }

      return firstUncompleted.id;
    },

    getTranslation(trans) {
      return trans.filter(t => t.lang === this.$i18n.locale)[0].value;
    },

    onCompletedLesson(completed) {
      if (!this.myCourse.userLessons.find(ul => ul.id === completed.id)) {
        this.myCourse.userLessons.push(completed);
      }
      if (this.isFinished()) {
        if (this.myCourse.hasExam !== true) {
          //fullført kurs, ingen eksamen
          const msg = {
            message: this.$t('labels.course.completed'),
            type: 'success',
            duration: 4000
          };
          this.$message(msg);
          this.$router.push({ name: 'my-courses-index' });
        } else {
          //må redirecte til eksamen
          // console.debug(
          //   'finsihed lessons => redirect to exam',
          //   this.myCourse,
          //   this.myCourse.id
          // );
          const msg = {
            message: this.$t('labels.course.completed_exam_remaining'),
            type: 'success',
            duration: 4000
          };
          this.$message(msg);
          const id = this.myCourse.id;
          this.$router.push({ name: 'my-course-exam', params: { mcid: id } });
        }
        // const msg =
        //   this.myCourse.hasExam !== true
        //     ? this.$t('labels.course.completed')
        //     : this.$t('labels.course.completed_exam_remaining');
        // this.$message({
        //   message: msg,
        //   type: 'success',
        //   duration: 5000
        // });
        // this.$router.push({ name: 'my-courses-index' });
      } else {
        this.selectedLesson = this.getNextLesson(completed.lessonId);
      }
    },

    isFinished() {
      return this.myCourse.userLessons.length === this.flatLessons.length;
    },

    getNextLesson(fromLessonId) {
      const modLength = this.myCourse.courseModules.length - 1;
      let next, pickNextModule;
      this.myCourse.courseModules.forEach((mod, modIdx) => {
        const lesLength = mod.lessons.length - 1;
        mod.lessons.forEach((les, lesIdx) => {
          if (pickNextModule && !next) {
            next = les;
          }
          if (les.id === fromLessonId) {
            if (lesIdx !== lesLength && !next) {
              next = mod.lessons[lesIdx + 1];
            } else {
              if (modLength === modIdx) {
                next = les;
              } else {
                pickNextModule = true;
              }
            }
          }
        });
      });
      next.disabled = false;
      return next.id;
    },

    navNext() {
      if (this.isLastLessonInCourse || !this.canNavNext) return;
      const idx = this.flatLessons.map(l => l.id).indexOf(this.selectedLesson);
      const next = this.flatLessons[idx + 1];
      if (next) this.selectedLesson = next.id;
      else this.$message({ message: this.$t('errors.general'), type: 'error' });
    },

    navBack() {
      if (this.isFirstLessonInCourse) return;
      const idx = this.flatLessons.map(l => l.id).indexOf(this.selectedLesson);
      const prev = this.flatLessons[idx - 1];
      if (prev) this.selectedLesson = prev.id;
      else this.$message({ message: this.$t('errors.general'), type: 'error' });
    },

    onLessonChange(selected) {
      this.selectedLesson = selected;
      this.checkIfLastLessonInModule(selected, this.myCourse);
    },

    checkIfLastLessonInModule(lessonId, course) {
      course.courseModules.forEach(mod => {
        const lessonSize = mod.lessons.length;
        mod.lessons.forEach((les, idx) => {
          if (lessonId === les.id) {
            if (idx === lessonSize - 1) {
              this.isLastLessonInModule = true;
            } else {
              this.isLastLessonInModule = false;
            }
          }
        });
      });
    }
  },

  computed: {
    isLastLessonInCourse: function() {
      return (
        this.flatLessons[this.flatLessons.length - 1].id === this.selectedLesson
      );
    },

    isFirstLessonInCourse: function() {
      return this.flatLessons[0].id === this.selectedLesson;
    },

    canNavNext: function() {
      return !!this.myCourse.userLessons.find(
        ul => ul.lessonId === this.selectedLesson
      );
    }
  },

  created() {
    const mcid = this.$router.history.current.params.mcid;
    this.getMyCourse(mcid);
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.my-courses", link: "/app/my-courses/"},
      {name: "models.a_course", link: ""}
    ]);
  }
};
</script>

<style>
.nav-hide {
  display: none;
}

.nav-show {
  fill: black;
}

.nav-disabled {
  fill: lightgray;
}

.lesson_selector {
  border: solid 1px black;
  font-weight: 600;
}

.lesson_selector_option {
  color: black !important;

}

.el-input__inner {
  color: black;
  font-weight: 700;
}
</style>
