<template>
  <section class="p-4 md:p-6">
    <section v-if="!lesson || !lesson.media || !lesson.media.mediaType">
      something is wrong..
    </section>
    <section v-if="lesson.media && lesson.media.mediaType">
      <section v-if="lesson.media.mediaType.name === 'info'">
        <info-form
            :text="
            lesson.media.text.filter(t => t.lang === $i18n.locale)[0].value
          "
            :images="lesson.media.images.filter(i => i.lang === $i18n.locale)"
        />
        <div class="flex-right mt-2" v-if="!courseCompleted">
          <ui-button @click="onNextClick" type="green">{{ $t ('actions.next') }}</ui-button>
        </div>
      </section>
      <section v-if="lesson.media.mediaType.name === 'image'">
        <image-form
            :text="
            lesson.media.text.filter(t => t.lang === $i18n.locale)[0].value
          "
            :images="lesson.media.images.filter(i => i.lang === $i18n.locale)"
        />
      </section>
      <section v-if="lesson.media.mediaType.name === 'vimeo'">
        <vimeo-player
            class="video-responsive"
            ref="player"
            :id="
            lesson.media.vimeoId.filter(t => t.lang === $i18n.locale)[0].value
          "
            @end="onVideoEnd"
        ></vimeo-player>
        <rich-text-field
            class="mt-4"
            :hideBorder="true"
            :showEditor="false"
            v-model="
            lesson.media.text.filter(t => t.lang === $i18n.locale)[0].value
          "
        />
      </section>
      <section v-if="lesson.media.mediaType.name === 'none'">
        <rich-text-field
            :showEditor="false"
            v-model="
              lesson.media.text.filter(t => t.lang === $i18n.locale)[0].value
            "
        />
      </section>

      <section v-if="lesson.media.mediaType.name !== 'info'">
        <section id="question" class="mt-4 question_text">
          <label for="questionText">{{ $tc ('models.question', 1) }}</label>
          <rich-text-field
              name="questionText"
              :showEditor="false"
              v-model="
              lesson.question.text.filter(t => t.lang === $i18n.locale)[0].value
            "
          />
          <section id="alternative" class="mt-4">
            <section v-if="lesson.question.questionType.handle === 'text'">
              <label for="answer">{{ $tc ('models.answer', 1) }}</label>
              <el-input name="answer" v-model="userAnswer"></el-input>
            </section>
            <section v-if="lesson.question.questionType.handle === 'multi'">
              <label for="alternatives">{{
                  $tc ('models.alternative', 2)
                }}</label>
              <div class="alternatives" name="alternatives">
                <button
                    v-for="alt in lesson.question.alternatives"
                    :key="`alt_${alt.id}`"
                    :disabled="!alternativesEnabled"
                    :class="`fill-alternative rounded mt-2 py-2 px-4 bg-transparent border alternative_option outline-none focus:outline-none get
                    ${isChosen(alt.id) ? getChosenClassName(alt.isCorrect) : ''} ${alternativesEnabled ? '' : 'opacity-50 cursor-not-allowed'}`"
                    @click="onAlternativeClick($event, alt)"
                >
                  {{ alt.text.filter (t => t.lang === $i18n.locale)[0].value }}
                </button>
              </div>
            </section>
          </section>
        </section>
        <div v-if="!courseCompleted" style="display:flex; flex-direction: row; justify-content: space-between;">
          <div>
            <div class="my_lesson_form_bottom" v-if="responseTextIcon !== null">
              <img class="response_text_icon"
                   :src="require('@/assets/images/' + responseTextIcon)">
              <p class="my_lesson_form_bottom_response_text" :class="responseTextColor">{{ $t (responseText) }}</p>
            </div>
          </div>
          <div class="my_lesson_form_bottom">
            <!-- Mount this button if the course is not completed -->
            <!-- TODO Find a more performant solution than this -->
            <!-- TODO Delete the wrapper div, they are the same -->
            <div class="my_lesson_form_next_button"
                 v-if="!courseCompleted && (alternativeChosen !== null || lesson.question.questionType.handle === 'text')">
              <div class="my_lesson_form_next_button"
                   v-if="!courseCompleted && ((alternativeChosen !== null && pressedCorrect) || lesson.question.questionType.handle === 'text')">
                <ui-button
                    @click="onNextClick"
                    type="green"
                >{{
                    nextExam
                        ? $t ('exam')
                        : nextComplete
                        ? $t ('complete')
                        : $t ('actions.next')
                  }}
                </ui-button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style="display:flex; flex-direction: row; justify-content: space-between;">
        <div v-if="courseCompleted">
          <div class="my_lesson_form_bottom" v-if="responseTextIcon !== null">
            <img class="response_text_icon"
                 :src="require('@/assets/images/' + responseTextIcon)">
            <p class="my_lesson_form_bottom_response_text" :class="responseTextColor">{{ $t (responseText) }}</p>
          </div>
        </div>

        <div class="my_lesson_form_bottom">
          <!-- Mount these buttons if the course is completed to navigate through lectures -->
          <div  v-if="courseCompleted">
            <ui-button
                class="remove-focus mr-4"
                v-if="!isFirstLessonInCourse && courseCompleted"
                @click.prevent="prevLecture"
                type="green"
            >{{ $t ('actions.prev_lecture') }}
            </ui-button>
            <ui-button
                class="remove-focus"
                v-if="!isLastLessonInCourse && courseCompleted"
                @click.prevent="nextLecture"
                type="green"
            >{{ $t ('actions.next_lecture') }}
            </ui-button>
          </div>
        </div>
      </div>


    </section>
  </section>
</template>

<script>
import InfoForm from './UiComponents/InfoFormComponent';
import ImageForm from './UiComponents/ImageFormComponent';
// import VideoForm from './UiComponents/VideoFormComponent';
import VimeoPlayer from './UiComponents/VimeoPlayer';
import RichTextField from '../../../components/form/RichTextField';
import UiButton from '@/components/ui/UiButton';

export default {
  props: {
    lesson: {
      type: Object,
      required: true
    },
    // Module related
    isLastLessonInModule: {
      type: Boolean,
      required: false
    },
    // Course related
    isFirstLessonInCourse: {
      type: Boolean,
      required: false
    },
    isLastLessonInCourse: {
      type: Boolean,
      required: false
    },
    nextExam: {
      type: Boolean,
      required: false
    },
    nextComplete: {
      type: Boolean,
      required: false
    },
    courseCompleted: {
      type: Boolean,
      required: true
    }
  },

  components: {
    UiButton,
    InfoForm,
    ImageForm,
    // VideoForm,
    VimeoPlayer,
    RichTextField
  },

  data() {
    return {
      userAnswer: '',
      pressedCorrect: false,
      hasWatchedVideo: false,
      alternativeChosen: null,
      responseText: null,
      responseTextColor: null,
      responseTextIcon: null,
    };
  },

  computed: {
    canPressNext: function () {
      return (
          (this.userAnswer !== '' || this.pressedCorrect) && this.hasWatchedVideo
      );
    },

    canAnswer: function () {
      return this.hasWatchedVideo;
    },

    alternativesEnabled: function () {
      return this.lesson.media.mediaType.name === 'vimeo'
          ? this.hasWatchedVideo
          : true;
    },

    currentLang: function () {
      return this.$i18n.locale;
    }
  },


  watch: {
    lesson(newVal) {
      // console.debug(newVal, oldVal);
      this.userAnswer = '';
      this.pressedCorrect = false;
      this.hasWatchedVideo = false;
      if (newVal.media.mediaType.name !== 'vimeo') {
        this.hasWatchedVideo = true;
      } else {
        if (this.$refs.player) {
          const newId = this.lesson.media.vimeoId.filter (
              t => t.lang === this.$i18n.locale
          )[0].value;
          this.$refs.player.load (newId);
        }
      }
    },

    nextExam(newVal, oldVal) {
      // console.debug (newVal, oldVal);
    },

    nextComplete(newVal, oldVal) {
      // console.debug (newVal, oldVal);
    },

    currentLang(newVal, oldVal) {
      if (
          this.lesson &&
          this.lesson.media.mediaType.name === 'vimeo' &&
          newVal !== oldVal
      ) {
        const newId = this.lesson.media.vimeoId.filter (
            t => t.lang === newVal
        )[0].value;
        this.$refs.player.load (newId);
      }
    },

    isLastLessonInModule(newVal, oldVal) {
      //må være her for å detektere endring
    }
  },

  methods: {
    getChosenClassName(correct) {
      if (correct) {
        return "alternative_chosen_wrong"
      } else {
        return "alternative_chosen_correct"
      }
    },

    isChosen(id) {
      return id === this.alternativeChosen;
    },

    onAlternativeClick(event, item) {
      this.alternativeChosen = item.id;
      if (item.isCorrect) {
        this.responseText = "misc.the_answer_is_correct"
        this.responseTextColor = "green_text";
        this.responseTextIcon = 'correct_sign.png';
        /*
        this.$swal({
          title: this.$t('feedback.correct'),
          type: 'success',
          showConfirmButton: false,
          timer: 1250
        });
         */
      } else {
        this.responseText = "misc.the_answer_is_wrong"
        this.responseTextColor = "red_text";
        this.responseTextIcon = 'warning_sign.png';
        /*
        this.$swal({
          title: this.$t('feedback.incorrect'),
          type: 'error',
          showConfirmButton: false,
          timer: 1250
        });
         */
      }
      this.pressedCorrect = item.isCorrect;
    },

    onVideoEnd(event) {
      this.hasWatchedVideo = true;
    },

    onNextClick() {
      this.userAnswer = '';
      this.pressedCorrect = false;
      this.hasWatchedVideo = false;
      this.responseTextIcon = null;
      this.responseText = null;
      this.responseTextColor = null;
      this.$emit ('onNextClick', this.lesson);
    },

    nextLecture() {
      this.responseTextIcon = null;
      this.responseText = null;
      this.responseTextColor = null;
      this.$emit ('nextLecture');
    },

    prevLecture() {
      this.responseTextIcon = null;
      this.responseText = null;
      this.responseTextColor = null;
      this.$emit ('prevLecture');
    }
  },

  created() {
    this.hasWatchedVideo = this._props.lesson.media.mediaType.name !== 'vimeo';
  }
};
</script>

<style>
.fill-alternative {
  text-align: inherit;
}

.alternatives {
  display: flex;
  flex-direction: column;
}

.flex-right {
  display: flex;
  justify-content: right;
}

.vimeo {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.remove-focus:focus {
  outline: none;
  box-shadow: none;
}

.question_text {
  font-weight: 700;
}

.button:first-child {
  /*margin-right: 1em;*/
}

.alternative_option:hover {
  background-color: #f5f5f5;
}

.alternative_chosen_correct {
  border: 2px solid red;
}

.alternative_chosen_wrong {
  border: 2px solid green;
}

.my_lesson_form_bottom {
  display: flex;
  margin-top: 1em;
}

.my_lesson_form_bottom_response_text {
  display: inline-flex;
  align-items: center;
}

.my_lesson_form_next_button {
  display: inline-flex;
  margin-left: auto;
}

.red_text {
  color: red;
}

.green_text {
  color: green;
}

.response_text_icon {
  height: 2em;
  width: 2em;
  align-self: center;
  margin-right: 1em;
}


</style>
