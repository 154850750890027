<template>
  <!-- <div class="bg-white rounded overflow-hidden border-t-4 border-theme-primary" v-if="course"> -->
  <section id="heading">
    <div class="p-4 md:p-6 font-bold uppercase">
      {{ getTranslation(course.title) }}
    </div>
    <div class="progress-bar-wrapper">
      <ui-progress-bar :percentage="computeCompleted"/>
    </div>
  </section>
</template>

<script>
import UiProgressBar from "@/components/ui/UiProgressBar";
export default {
  props: {
    course: {
      type: Object,
      required: true
    }

  },

  data: () => {
    return {
    };
  },

  components: {
    UiProgressBar
    // MyLessonForm
  },

  methods: {
    getTranslation(trans) {
      return trans.filter(t => t.lang === this.$i18n.locale)[0].value;
    }
  },

  computed: {
    computeCompleted() {
      let numberOfLessons = 0;
      this.course.courseModules.map(cm => {
        numberOfLessons += cm.lessons.length;
      });
      const p = Math.floor(
        (this.course.userLessons.length / numberOfLessons) * 100
      );
      return p;
    },
    progressColor() {
      if (this.computeCompleted < 20) return '#e6a23c';
      if (this.computeCompleted > 20 && this.computeCompleted < 100)
        return '#409eff';
      if (this.computeCompleted === 100) return '#67c23a';
      else return '';
    }
  }
};
</script>

<style>
.course-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.nav-icon {
  height: 40px;
}

.progress-bar-wrapper {
  margin: auto 2em;
}
</style>
